.feed-mapping-main-page {
  background-color: var(--white);
  padding: 20px 40px;
  border-radius: 2px;
  margin: 0 0 10px;

  &.ant-form-item {
    justify-content: space-between;
  }

  &.form-box {
    padding: 35px;
  }

  .ant-form-item-label {
    max-width: 200px;
  }

  .ant-form-item-label {
    max-width: 200px;
  }

  .ant-select-single {
    max-width: 425px;
  }
}

.ant-cascader-menu-item[data-path-key="__EMPTY__"] {
  display: none;
}

.cascader-add-constant-button {
  padding: 0 15px;
  border: 1px solid transparent;
  line-height: 40px;
  cursor: pointer;
  transition: border-color 0.3s;

  &:hover {
    border-color: var(--blue);
  }
}

@layout-body-background: #FFF;@blue-base: #2EB8E6;@error-color: #E64545;@disabled-color: #7A8399;@btn-default-color: #000000;@btn-disable-bg: #FFF;@btn-disable-border: #CFD8E6;@input-placeholder-color: #8FA4BF;@input-bg: #F0F5FA;@input-color: #000000;@input-border-color: #F0F5FA;@input-disabled-bg: #F5F9FC;@radio-dot-color: #2FBF00;@radio-button-checked-bg: #2FBF00;@radio-button-hover-color: #39B312;@radio-disabled-button-checked-bg: #B2E6A1;@radio-button-focus-shadow: none;@radio-button-active-color: #FFFFFF;@radio-disabled-button-checked-color: #FFFFFF;@switch-color: #2FBF00;@menu-icon-size-lg: 24px;