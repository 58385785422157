.filters-configuration {
  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: stretch;
  }
  &__item {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    border: 1px solid var(--gray-light);
    border-radius: 4px;
    &--ghost {
      z-index: 9999;
      background: var(--white);
      box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%);
    }
  }
  &__drag {
    color: var(--gray);
    cursor: move;
  }
  &__field {
    margin: 0 !important;
    flex-grow: 1;
  }
  &__add {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  &__select {
    flex-grow: 1;
  }
}

.add-new-filter {
  margin: 0 0 0 10px;
  display: flex;
  align-items: center;
  gap: 8px;
}

@layout-body-background: #FFF;@blue-base: #2EB8E6;@error-color: #E64545;@disabled-color: #7A8399;@btn-default-color: #000000;@btn-disable-bg: #FFF;@btn-disable-border: #CFD8E6;@input-placeholder-color: #8FA4BF;@input-bg: #F0F5FA;@input-color: #000000;@input-border-color: #F0F5FA;@input-disabled-bg: #F5F9FC;@radio-dot-color: #2FBF00;@radio-button-checked-bg: #2FBF00;@radio-button-hover-color: #39B312;@radio-disabled-button-checked-bg: #B2E6A1;@radio-button-focus-shadow: none;@radio-button-active-color: #FFFFFF;@radio-disabled-button-checked-color: #FFFFFF;@switch-color: #2FBF00;@menu-icon-size-lg: 24px;