.release-delivery-table {
  table {
    .resend-column {
      svg path {
        &:first-child {
          fill: transparent !important;
        }
      }

      .ant-btn {
        border: 0;
      }
    }
  }

  &__error {
    p {
      margin: 0;
    }
  }
}

.delivery-status {
  &__previous {
    opacity: 0.3;
  }
  &__icon {
    color: var(--gray-light);
    font-size: 12px;
  }
}

@layout-body-background: #FFF;@blue-base: #2EB8E6;@error-color: #E64545;@disabled-color: #7A8399;@btn-default-color: #000000;@btn-disable-bg: #FFF;@btn-disable-border: #CFD8E6;@input-placeholder-color: #8FA4BF;@input-bg: #F0F5FA;@input-color: #000000;@input-border-color: #F0F5FA;@input-disabled-bg: #F5F9FC;@radio-dot-color: #2FBF00;@radio-button-checked-bg: #2FBF00;@radio-button-hover-color: #39B312;@radio-disabled-button-checked-bg: #B2E6A1;@radio-button-focus-shadow: none;@radio-button-active-color: #FFFFFF;@radio-disabled-button-checked-color: #FFFFFF;@switch-color: #2FBF00;@menu-icon-size-lg: 24px;