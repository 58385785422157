.query-builder-container {
  padding: 0;

  .group--header {
    margin: 0;
    padding: 0;

    .group--conjunctions {
      margin: 0;
    }
  }

  .group--conjunctions {
    z-index: 100;
    .ant-btn {
      background: var(--white);

      &:hover,
      &:active {
        border-color: var(--green);
      }

      &:active {
        color: var(--green);
      }

      &.ant-btn-primary {
        background-color: var(--green);
        border-color: var(--green);
      }
    }
  }

  .group {
    background-color: transparent;
    border: 0;
    padding: 8px;
  }

  .group--children {
    margin-bottom: 0;

    &:empty {
      margin: 0;
    }

    &.one--child {
      padding-left: 0;
    }

    &.hide--line {
      .rule::before {
        display: none;
      }
    }

    .group-or-rule-container {
      padding-right: 0;
      border-radius: 4px;
    }

    .group {
      background-color: var(--gray-lightest);
      border: 1px solid var(--gray-light);
      transition: border-color 0.3s;

      &:has(> .group--header .action--DELETE:hover) {
        border-color: var(--red);
      }
    }

    .rule-container {
      border: 0;
      background-color: transparent;
    }
  }

  .group--children > .group-or-rule-container > .group-or-rule {
    &::before,
    &::after {
      border-color: var(--gray-light);
    }

    &:first-child::before {
      top: -21px;
      height: calc(50% + 24px);
    }
  }

  .rule {
    align-items: center;
    border-color: var(--gray-light);
    transition: border-color 0.3s;

    &:has(.action--DELETE:hover) {
      border-color: var(--red);
    }
  }

  .action--DELETE {
    color: var(--gray);
    font-size: 24px;
    transition: color 0.3s;

    &:hover {
      color: var(--red);
    }
  }

  .action--ADD-RULE {
    margin: 0 10px 0 0;
  }

  .action--ADD-RULE,
  .action--ADD-GROUP {
    .anticon {
      color: var(--gray);
    }
  }

  .qb-drag-handler {
    svg {
      fill: var(--gray-light);
    }
  }

  .rule--field {
    min-width: 200px;
    margin: 0 10px 0 0;
  }

  .rule--operator {
    width: 150px;
    margin: 0 10px 0 0;
  }

  .rule--value {
    flex-grow: 1;
  }

  .rule--widget {
    display: inline-flex;
    align-items: center;
    justify-content: stretch;
    gap: 8px;
    flex-grow: 1;
  }

  .widget--widget {
    margin-left: 0;
  }

  .rule--value,
  .widget--widget {
    flex-grow: 1;
  }

  .rule--func--arg-value {
    max-width: 80px;
  }

  .ant-switch .ant-switch-inner {
    font-weight: bold;
    text-transform: uppercase;
    min-width: 24px;
    text-align: center;
    display: inline-block;
  }

  .rule--header {
    align-items: flex-start;
  }

  .rule--body {
    display: flex;
    flex-wrap: nowrap;
    text-align: left;
  }

  .rule-with-error {
    border: 0;

    .rule--error {
      font-size: 12px;
      letter-spacing: 0;
      color: #e64545;
    }
  }
}

.query-builder-wrapper {
  transition: opacity 0.3s;

  .query-builder {
    margin: 0;
    font-family: "Raleway", sans-serif;

    > .group-container {
      padding: 0;

      > .group {
        padding: 0;
      }
    }
  }

  .query-builder-container {
    padding: 8px;
    border-radius: 8px;
    border: 1px solid var(--gray-light);
    transition: border 0.3s;

    &:hover,
    &:focus-within {
      border-color: var(--blue);
    }
  }

  &--error .query-builder-container {
    border-color: #e64545 !important;
  }

  .rule--value {
    display: flex;
    align-items: center;
  }

  .rule--widget--DATE {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .rule--func--wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .rule--func {
    &--bracket {
      &-before,
      &-after {
        display: none;
      }
    }

    &--arg-value {
      > .rule--widget {
        margin-left: 0;
      }
    }
  }

  .widget--valuesrc {
    margin: 0;
  }
}

@layout-body-background: #FFF;@blue-base: #2EB8E6;@error-color: #E64545;@disabled-color: #7A8399;@btn-default-color: #000000;@btn-disable-bg: #FFF;@btn-disable-border: #CFD8E6;@input-placeholder-color: #8FA4BF;@input-bg: #F0F5FA;@input-color: #000000;@input-border-color: #F0F5FA;@input-disabled-bg: #F5F9FC;@radio-dot-color: #2FBF00;@radio-button-checked-bg: #2FBF00;@radio-button-hover-color: #39B312;@radio-disabled-button-checked-bg: #B2E6A1;@radio-button-focus-shadow: none;@radio-button-active-color: #FFFFFF;@radio-disabled-button-checked-color: #FFFFFF;@switch-color: #2FBF00;@menu-icon-size-lg: 24px;