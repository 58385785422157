.ant-pagination {
  display: flex;
  align-items: center;
  position: relative;

  .ant-pagination-total-text {
    position: absolute;
    left: 0;
    height: auto;
    font-size: 14px;
  }

  .ant-pagination-item {
    width: auto;
    height: auto;
    font-weight: 600;
    margin: 0 10px 0 0;
    border: 1px solid transparent;
    background-color: transparent;

    a {
      color: var(--blue);
    }

    &:hover {
      font-weight: 700;
      border: 1px solid var(--gray-light);

      a {
        color: var(--black);
      }
    }

    &.ant-pagination-item-active {
      font-weight: 600;
      background-color: transparent;
      a {
        color: var(--black);
      }
    }
  }

  .ant-pagination-prev {
    border: 0;
    margin: 0 18px 0 0;

    .anticon-left {
      position: relative;

      &:after {
        position: absolute;
        font-family: "icomoon";
        content: "\e917";
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 9px;
        color: var(--blue);
        margin: 2px 0 0;
      }
    }

    svg {
      display: none !important;
    }

    &.ant-pagination-disabled {
      .anticon-left {
        &:after {
          color: var(--gray);
        }
      }
    }

    .ant-pagination-item-link {
      border: 0;
      background-color: transparent;

      .anticon {
        width: 32px;
        height: 32px;
      }
    }
  }

  .ant-pagination-next {
    border: 0;
    margin: 0 0 0 7px;

    svg {
      display: none !important;
    }
    .anticon-right {
      position: relative;

      &:after {
        position: absolute;
        font-family: "icomoon";
        content: "\e91e";
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: var(--blue);
        font-size: 9px;
        margin: -3px 0 0;
      }
    }

    &.ant-pagination-disabled {
      .anticon-right {
        &:after {
          color: var(--gray);
        }
      }
    }

    .ant-pagination-item-link {
      border: 0;
      background-color: transparent;
    }
  }

  .ant-pagination-options {
    margin: 0 0 0 20px;

    .ant-pagination-options-size-changer .ant-select-selector {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 118px;
      border-radius: 2px;
      background-color: var(--gray-lightest);
      line-height: 40px;
      font-size: 14px;
      font-weight: 400;
      border: 0;
    }

    .ant-pagination-options-quick-jumper {
      margin: 0 0 0 20px;
      height: 40px;

      input {
        margin: 0 0 0 12px;
        padding: 9px 10px;
        background-color: var(--gray-lightest);
        width: 62px;
      }
    }
  }
}

.ant-pagination-total-text {
  font-size: 12px;
  font-variant-numeric: lining-nums !important;

  span {
    font-weight: 700;
  }
}

.ant-pagination-options {
  .ant-pagination-options-size-changer {
    .ant-select-selector {
      padding: 0 !important;
      .anticon-down {
        position: relative;

        &:after {
          position: absolute;
          content: "\e91b";
          font-family: "icomoon";
          visibility: visible;
          top: 0;
          right: 0;
          font-size: 10px;
        }

        svg {
          display: none !important;
        }
      }
    }
  }
}

.ant-pagination-options {
  .ant-select {
    background-color: t transparent !important;
    padding: 0 !important;
  }

  .ant-select-selector {
    background-color: var(--gray-lightest) !important;
  }

  .ant-select-selection-item {
    background-color: var(--gray-lightest) !important;
    padding: 0 10px !important;
    color: var(--black);
    font-variant-numeric: lining-nums;
  }
}
