.variable-type-indicator() {
  display: inline-flex;
  background: var(--indicator-color, var(--pink));
  color: var(--white);
  place-content: center;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  padding: 2px;
  font-size: 12px;
  line-height: 12px;
  margin-right: 4px;
  font-weight: bold;
  font-style: normal;
}

.variable() {
  .variable {
    user-select: none;

    line-height: 1.4;
    display: inline-flex;
    align-items: center;
    border-radius: 2px;
    font-variant: lining-nums;

    &.ProseMirror-selectednode {
      outline: 2px solid var(--pink);
    }

    &__inner {
      display: inline-flex;
      align-items: center;
    }

    color: var(--pink);
    background: var(--pink-transparent);
    text-decoration: inherit;

    &:before,
    &:after {
      font-style: normal;
      font-family: var(--monospace-font);
    }

    &:before {
      content: "{";
    }

    &:after {
      content: "}";
    }

    &__type {
      .variable-type-indicator();
    }

    &__label {
      &:not(.variable__label--read-only) {
        cursor: pointer;
        text-decoration: underline dashed;

        &:hover {
          text-decoration: none;
        }
      }

      &--unknown {
        text-decoration: underline wavy var(--red);
      }
    }

    &__remove {
      color: var(--pink);
      font-size: 16px;
      &:hover {
        color: var(--red);
      }
    }
  }

  a span.variable {
    color: var(--blue);
    background: var(--blue-transparent-10);
    --indicator-color: var(--blue);
  }
}
