@import "~antd/dist/antd.less";
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,500;0,600;0,700;1,400&family=Open+Sans:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@400;500;700&display=swap");
@import "../assets/less/icomoon.css";
@import "../assets/less/_main.less";

.app {
  height: 100vh;
  overflow: hidden;

  &__header {
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;

    &--secondary {
      background-color: #f5f9fc;

      .ant-layout {
        background-color: transparent;
      }

      .ant-page-header-heading {
        margin: 0;
        padding: 16px 0;
      }

      .ant-page-header-heading-title {
        font-size: 24px;
        font-weight: 700;
      }
    }
  }

  &__scroller {
    overflow: auto;
    flex-grow: 1;
  }
}

@layout-body-background: #FFF;@blue-base: #2EB8E6;@error-color: #E64545;@disabled-color: #7A8399;@btn-default-color: #000000;@btn-disable-bg: #FFF;@btn-disable-border: #CFD8E6;@input-placeholder-color: #8FA4BF;@input-bg: #F0F5FA;@input-color: #000000;@input-border-color: #F0F5FA;@input-disabled-bg: #F5F9FC;@radio-dot-color: #2FBF00;@radio-button-checked-bg: #2FBF00;@radio-button-hover-color: #39B312;@radio-disabled-button-checked-bg: #B2E6A1;@radio-button-focus-shadow: none;@radio-button-active-color: #FFFFFF;@radio-disabled-button-checked-color: #FFFFFF;@switch-color: #2FBF00;@menu-icon-size-lg: 24px;