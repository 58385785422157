.feed-extra-mapping__headings-row {
  margin: 0 0 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-variant-numeric: lining-nums !important;

  .feed-extra-mapping__headings-col {
    width: 50%;

    &:first-child {
      width: calc(50% - 56px);
      margin-right: 20px;
    }

    &:last-child {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-grow: 1;
    }

    h2 {
      margin: 0;
    }
  }
}

.feed-default-mapping__heading-search {
  display: flex;
  justify-content: space-between;
  margin: 0 0 20px;
  align-items: center;

  .feed-default-mapping__heading {
    margin: 0;
  }
}

.feed-extra-mapping__heading,
.feed-default-mapping__heading {
  color: var(--gray);
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0 0 15px;
}

.feed-extra-mapping-form {
}

.default-mappings {
  ul {
    padding: 0;
    margin: 0 0 25px;
    list-style: none;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
      0 9px 28px 8px rgba(0, 0, 0, 0.05);

    li {
      padding: 11px 15px;
      background-color: var(--white);
      word-break: break-all;

      &:nth-child(odd) {
        background-color: var(--gray-lightest);
      }

      span {
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        overflow: hidden;
        padding-right: 30px;
      }
    }
  }
}

.form-box-with-pagination {
  padding: 20px 40px;

  .ant-pagination {
    justify-content: flex-end;
  }
}

.feed-extra-mapping__row {
  position: relative;

  .delete-button {
    font-size: 28px;
    margin-top: 3px;
  }
}

.input-search-wrapper {
  position: relative;
  max-width: 200px;
  margin-left: auto;

  .input-search__icon {
    color: var(--gray);
    font-size: 20px;
    position: absolute;
    top: 10px;
    left: 12px;
    z-index: 20;
  }

  &:focus-within .input-search__icon {
    display: none;
  }

  .input-search {
    &::placeholder {
      padding-left: 25px;
    }
  }
}

.input-search--not-empty {
  &:after {
    display: none;
  }
}

//popup-override

.popup-override {
  width: 480px !important;

  .ant-modal-confirm-title {
    background-color: var(--gray-lightest);
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 17px 20px;
    letter-spacing: 1.1px;
  }

  .ant-modal-body {
    padding: 0 0 24px 0;
  }

  &__content {
    padding: 25px 30px;
  }

  .ant-modal-confirm-btns {
    margin: 0 30px 0 0;
  }

  &__warning {
    font-size: 14px;
    font-weight: bold;
    margin: 0 0 10px;
  }

  &__sub-heading {
    color: #7a8399;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 1.1px;
    margin: 0 0 3px;
    font-variant-numeric: lining-nums !important;
  }

  &__text {
    font-size: 12px;
    font-variant-numeric: lining-nums !important;
    margin: 0 0 13px;

    &:last-child {
      margin: 0;
    }
  }
}

//ant-empty form-box

.ant-empty.form-box {
  padding: 45px;
}

@layout-body-background: #FFF;@blue-base: #2EB8E6;@error-color: #E64545;@disabled-color: #7A8399;@btn-default-color: #000000;@btn-disable-bg: #FFF;@btn-disable-border: #CFD8E6;@input-placeholder-color: #8FA4BF;@input-bg: #F0F5FA;@input-color: #000000;@input-border-color: #F0F5FA;@input-disabled-bg: #F5F9FC;@radio-dot-color: #2FBF00;@radio-button-checked-bg: #2FBF00;@radio-button-hover-color: #39B312;@radio-disabled-button-checked-bg: #B2E6A1;@radio-button-focus-shadow: none;@radio-button-active-color: #FFFFFF;@radio-disabled-button-checked-color: #FFFFFF;@switch-color: #2FBF00;@menu-icon-size-lg: 24px;