.login-screen {
  display: flex;
  justify-content: center;
  align-items: center;

  height: calc(100vh - var(--main-content-padding-bottom) - var(--main-content-padding-top));

  .login-form {
    width: 280px;

    .ant-form-item-explain {
      display: none;
    }

    .logo-indent {
      margin-bottom: 36px;
    }
    .ant-row.ant-form-item {
      margin-bottom: 10px;
    }
    .ant-input-affix-wrapper.ant-input-password {
      height: 40px;
      background-color: var(--gray-lightest);
      .ant-input {
        height: 100%;
      }
    }
    .ant-input-password-icon {
      height: 20px;
      width: 32px;
      color: var(--blue);
      font-family: inherit;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 20px;
    }
    .login-form-button {
      margin-top: 20px;
      width: 100%;
      text-transform: uppercase;
    }
    .link-wrapper {
      margin-top: 16px;
      display: flex;
      justify-content: flex-end;
    }
    .reset-password {
      height: 20px;
      color: var(--blue);
      font-family: inherit;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 20px;
    }
    .ant-btn .ant-btn-primary {
      font-family: Rawline, serif;
      height: 400px !important;
      line-height: 15px;
      font-size: 11px;
      letter-spacing: 1.1px;
      color: var(--white);
      font-weight: bold;
    }
    .login-error {
      color: #e64545;
      font-family: Raleway, serif;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 20px;
      margin-top: 13px;
    }
  }
}

@layout-body-background: #FFF;@blue-base: #2EB8E6;@error-color: #E64545;@disabled-color: #7A8399;@btn-default-color: #000000;@btn-disable-bg: #FFF;@btn-disable-border: #CFD8E6;@input-placeholder-color: #8FA4BF;@input-bg: #F0F5FA;@input-color: #000000;@input-border-color: #F0F5FA;@input-disabled-bg: #F5F9FC;@radio-dot-color: #2FBF00;@radio-button-checked-bg: #2FBF00;@radio-button-hover-color: #39B312;@radio-disabled-button-checked-bg: #B2E6A1;@radio-button-focus-shadow: none;@radio-button-active-color: #FFFFFF;@radio-disabled-button-checked-color: #FFFFFF;@switch-color: #2FBF00;@menu-icon-size-lg: 24px;