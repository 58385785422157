.rich-text-editor-toolbar {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;

  &__button {
    border-radius: 2px;
    transition: background 0.3s, color 0.3s;
    font-size: 20px;
    &--active {
      color: var(--blue);
      background: var(--blue-transparent-10);
      &:hover {
        background: var(--blue-transparent-20);
      }
    }
  }

  &__divider {
    width: 4px;
  }
}

@layout-body-background: #FFF;@blue-base: #2EB8E6;@error-color: #E64545;@disabled-color: #7A8399;@btn-default-color: #000000;@btn-disable-bg: #FFF;@btn-disable-border: #CFD8E6;@input-placeholder-color: #8FA4BF;@input-bg: #F0F5FA;@input-color: #000000;@input-border-color: #F0F5FA;@input-disabled-bg: #F5F9FC;@radio-dot-color: #2FBF00;@radio-button-checked-bg: #2FBF00;@radio-button-hover-color: #39B312;@radio-disabled-button-checked-bg: #B2E6A1;@radio-button-focus-shadow: none;@radio-button-active-color: #FFFFFF;@radio-disabled-button-checked-color: #FFFFFF;@switch-color: #2FBF00;@menu-icon-size-lg: 24px;