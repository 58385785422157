.feed-operation-tag {
  text-transform: uppercase;
  font-weight: bold;
  margin: 0;
  padding: 2px;
  align-items: center;

  &__icon {
    font-size: 14px;
    margin: 0 !important;
  }

  &__label {
    margin: 0 !important;
    padding: 0 4px;
    line-height: 1;
  }

  &--import {
    color: #08979c;
    background: #e6fffb;
    border-color: #87e8de;
  }

  &--export {
    color: #6076e1;
    background: #f0f5ff;
    border-color: #adc6ff;
  }
}

@layout-body-background: #FFF;@blue-base: #2EB8E6;@error-color: #E64545;@disabled-color: #7A8399;@btn-default-color: #000000;@btn-disable-bg: #FFF;@btn-disable-border: #CFD8E6;@input-placeholder-color: #8FA4BF;@input-bg: #F0F5FA;@input-color: #000000;@input-border-color: #F0F5FA;@input-disabled-bg: #F5F9FC;@radio-dot-color: #2FBF00;@radio-button-checked-bg: #2FBF00;@radio-button-hover-color: #39B312;@radio-disabled-button-checked-bg: #B2E6A1;@radio-button-focus-shadow: none;@radio-button-active-color: #FFFFFF;@radio-disabled-button-checked-color: #FFFFFF;@switch-color: #2FBF00;@menu-icon-size-lg: 24px;