.header {
  display: flex;
  align-items: center;
  background-color: var(--black) !important;

  &.ant-layout-header {
    height: var(--header-height);
    line-height: 1.2;
    padding: 10px 40px 10px 8px;
  }

  .user-actions {
    max-width: 300px;
    margin-left: auto;
    color: var(--white);

    .user-name {
      margin: 0 10px 0 0;
    }

    .anticon-caret-down {
      width: 12px;
    }
  }

  .menu-trigger {
    color: rgba(255, 255, 255, 0.65);
    font-size: 18px;
    transition: color 0.3s;

    &:hover {
      color: var(--white);
    }
  }
}

.nav-bar__popover {
  width: 200px !important;
  top: 45px !important;
  position: fixed;

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner-content {
    padding: 0;
  }

  .ant-popover-inner {
    box-shadow: 0 4px 7px 0 rgba(143, 164, 191, 0.2);
  }

  .user-links a {
    padding: 10px 15px;
    border: 1px solid transparent;
    cursor: pointer;
    color: var(--black);
    font-size: 14px;
    display: block;

    &:hover {
      border: 1px solid var(--blue);
      border-radius: 2px 2px 0 0;
    }
  }
}

@layout-body-background: #FFF;@blue-base: #2EB8E6;@error-color: #E64545;@disabled-color: #7A8399;@btn-default-color: #000000;@btn-disable-bg: #FFF;@btn-disable-border: #CFD8E6;@input-placeholder-color: #8FA4BF;@input-bg: #F0F5FA;@input-color: #000000;@input-border-color: #F0F5FA;@input-disabled-bg: #F5F9FC;@radio-dot-color: #2FBF00;@radio-button-checked-bg: #2FBF00;@radio-button-hover-color: #39B312;@radio-disabled-button-checked-bg: #B2E6A1;@radio-button-focus-shadow: none;@radio-button-active-color: #FFFFFF;@radio-disabled-button-checked-color: #FFFFFF;@switch-color: #2FBF00;@menu-icon-size-lg: 24px;