.asset-naming-fields {
  &__delimiter {
    padding: 4px 0;
  }
  &__remove-part {
    font-size: 20px;
    margin-left: 8px;
    padding-top: 10px;
  }

  &__errors {
    margin-bottom: 16px;
  }

  &__parts {
    padding: 0;
    list-style-position: inside;
    counter-set: list;

    li {
      display: flex;
      counter-increment: list;
      align-items: flex-start;
      color: var(--gray);
      font-size: 16px;
      margin-bottom: 16px;

      .ant-form-item {
        margin: 0 !important;
        flex-grow: 1;
      }

      &::before {
        content: counter(list) ".";
        font-weight: bold;
        margin-right: 8px;
        padding-top: 10px;
      }
    }
  }

  &__sample {
    border-radius: 4px;
    background-color: var(--gray-lightest);
    padding: 8px;
    color: #427ac5;
    text-align: center;
    font-family: monospace;
    margin-bottom: 16px;
    box-shadow: inset 1px 2px 5px rgba(0, 0, 0, 0.08);
    word-break: break-all;
  }
}

@layout-body-background: #FFF;@blue-base: #2EB8E6;@error-color: #E64545;@disabled-color: #7A8399;@btn-default-color: #000000;@btn-disable-bg: #FFF;@btn-disable-border: #CFD8E6;@input-placeholder-color: #8FA4BF;@input-bg: #F0F5FA;@input-color: #000000;@input-border-color: #F0F5FA;@input-disabled-bg: #F5F9FC;@radio-dot-color: #2FBF00;@radio-button-checked-bg: #2FBF00;@radio-button-hover-color: #39B312;@radio-disabled-button-checked-bg: #B2E6A1;@radio-button-focus-shadow: none;@radio-button-active-color: #FFFFFF;@radio-disabled-button-checked-color: #FFFFFF;@switch-color: #2FBF00;@menu-icon-size-lg: 24px;