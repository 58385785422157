.readonly-condition-group {
  border-radius: 4px;
  border: 1px solid var(--gray-light);
  padding: 4px;

  flex-grow: 1;
  flex-basis: 100%;

  &__operators {
    display: flex;
    gap: 4px;
    margin-bottom: 4px;

    &:empty {
      display: none;
    }

    > div {
      background: var(--green);
      color: var(--white);
      text-transform: uppercase;
      font-weight: bold;
      font-size: 12px;
      line-height: 12px;
      padding: 2px 4px;
      border-radius: 2px;
    }
  }

  &__expressions {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
  }
}

.readonly-condition-rule {
  border-radius: 4px;
  border: 1px solid var(--gray-light);
  padding: 4px 8px;

  &__field {
    font-weight: 600;
  }
  &__value {
    font-weight: 600;
    color: var(--blue-darker);
    font-feature-settings: "lnum";
  }
}

@layout-body-background: #FFF;@blue-base: #2EB8E6;@error-color: #E64545;@disabled-color: #7A8399;@btn-default-color: #000000;@btn-disable-bg: #FFF;@btn-disable-border: #CFD8E6;@input-placeholder-color: #8FA4BF;@input-bg: #F0F5FA;@input-color: #000000;@input-border-color: #F0F5FA;@input-disabled-bg: #F5F9FC;@radio-dot-color: #2FBF00;@radio-button-checked-bg: #2FBF00;@radio-button-hover-color: #39B312;@radio-disabled-button-checked-bg: #B2E6A1;@radio-button-focus-shadow: none;@radio-button-active-color: #FFFFFF;@radio-disabled-button-checked-color: #FFFFFF;@switch-color: #2FBF00;@menu-icon-size-lg: 24px;