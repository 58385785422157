.spreadsheet-column {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 8px;

  &__name {
    flex-basis: 15%;
    max-width: 15%;
  }

  &__type {
    flex-basis: calc(15% - 11px);
    max-width: calc(15% - 11px);
  }

  &__value {
    flex-basis: 25%;
    max-width: 25%;
  }

  &__template {
    flex-basis: 15%;
    max-width: 15%;

    display: flex;
    align-items: center;
    gap: 8px;

    .ant-form-item {
      flex-grow: 1;
    }
  }

  &__template-help {
    cursor: help;
    color: var(--blue);
  }

  &__date-format,
  &__timezone {
    flex-basis: 35%;
    max-width: 35%;
  }

  &__number-format {
    flex-basis: 50%;
    max-width: 50%;
  }
}

@layout-body-background: #FFF;@blue-base: #2EB8E6;@error-color: #E64545;@disabled-color: #7A8399;@btn-default-color: #000000;@btn-disable-bg: #FFF;@btn-disable-border: #CFD8E6;@input-placeholder-color: #8FA4BF;@input-bg: #F0F5FA;@input-color: #000000;@input-border-color: #F0F5FA;@input-disabled-bg: #F5F9FC;@radio-dot-color: #2FBF00;@radio-button-checked-bg: #2FBF00;@radio-button-hover-color: #39B312;@radio-disabled-button-checked-bg: #B2E6A1;@radio-button-focus-shadow: none;@radio-button-active-color: #FFFFFF;@radio-disabled-button-checked-color: #FFFFFF;@switch-color: #2FBF00;@menu-icon-size-lg: 24px;