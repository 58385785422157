.color-picker {
  display: flex;

  &__button {
    width: 40px;
    min-width: 40px;
    flex: 0 0 auto;
    height: 40px;
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid var(--gray-light);
    transition: border-color 0.3s;

    &:hover {
      border-color: var(--blue);
    }
  }

  &__swatch {
    width: 100%;
    height: 100%;
    border-radius: 2px;
  }

  &__popover {
    .ant-popover-inner {
      border-radius: 12px;
    }
    .ant-popover-inner-content {
      padding: 4px;
    }
  }

  &__input {
    flex-grow: 1;
    margin-left: 8px;
  }
}

@layout-body-background: #FFF;@blue-base: #2EB8E6;@error-color: #E64545;@disabled-color: #7A8399;@btn-default-color: #000000;@btn-disable-bg: #FFF;@btn-disable-border: #CFD8E6;@input-placeholder-color: #8FA4BF;@input-bg: #F0F5FA;@input-color: #000000;@input-border-color: #F0F5FA;@input-disabled-bg: #F5F9FC;@radio-dot-color: #2FBF00;@radio-button-checked-bg: #2FBF00;@radio-button-hover-color: #39B312;@radio-disabled-button-checked-bg: #B2E6A1;@radio-button-focus-shadow: none;@radio-button-active-color: #FFFFFF;@radio-disabled-button-checked-color: #FFFFFF;@switch-color: #2FBF00;@menu-icon-size-lg: 24px;