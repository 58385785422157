.multi-select {
  &.ant-select.ant-select-multiple {
    border: 1px solid var(--gray-light);
    border-radius: 4px;
    padding: 5px 4px;
    background: var(--white);

    .ant-select-selector {
      align-items: stretch;
    }

    .ant-select-selection-overflow {
      min-height: 100%;
      align-items: center;
      gap: 4px;
    }

    .ant-select-selection-search-input,
    .ant-select-selection-placeholder {
      background: none !important;
    }
  }

  &__dropdown {
    .ant-cascader-menu {
      height: auto;
      max-height: 300px;
    }
  }
}

@layout-body-background: #FFF;@blue-base: #2EB8E6;@error-color: #E64545;@disabled-color: #7A8399;@btn-default-color: #000000;@btn-disable-bg: #FFF;@btn-disable-border: #CFD8E6;@input-placeholder-color: #8FA4BF;@input-bg: #F0F5FA;@input-color: #000000;@input-border-color: #F0F5FA;@input-disabled-bg: #F5F9FC;@radio-dot-color: #2FBF00;@radio-button-checked-bg: #2FBF00;@radio-button-hover-color: #39B312;@radio-disabled-button-checked-bg: #B2E6A1;@radio-button-focus-shadow: none;@radio-button-active-color: #FFFFFF;@radio-disabled-button-checked-color: #FFFFFF;@switch-color: #2FBF00;@menu-icon-size-lg: 24px;