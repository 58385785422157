.ant-tabs {
  .ant-tabs-tab {
    justify-content: center;
    padding: 18px 40px;
    margin: 0 1px 0 0;

    .anticon {
      margin: 0;
    }

    .ant-tabs-tab-btn {
      font-size: 11px;
      text-transform: uppercase;
      font-weight: 700;
      text-align: center;
      color: var(--black);
      letter-spacing: 1.1px;
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &.ant-tabs-tab-active {
      color: var(--black);

      &::after {
        display: none !important;
      }
    }
  }

  .ant-tabs-ink-bar {
    background-color: #812ee6 !important;
    height: 3px !important;
  }

  .ant-tabs-extra-content {
    button {
      &:last-child {
        margin: 0 0 0 10px;
      }
    }
  }

  > .ant-tabs-nav .ant-tabs-nav-wrap,
  > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    /* Fix issue with "overflow: hidden" not working on Safari */
    transform: none;
  }
}

.ant-tabs-top > .ant-tabs-nav {
  margin: 0 0 30px 0;
}

.ant-tabs-top.ant-tabs-card {
  > .ant-tabs-nav {
    margin-bottom: 0;
  }

  .ant-tabs-content-holder {
    border: 1px solid #f0f0f0;
    border-radius: 0 0 2px 2px;
    border-top: 0;
    overflow: hidden;
  }
}
