.manual-export-form {
  .ant-upload-select {
    .ant-upload {
      color: var(--gray);
      font-size: 11px;
      font-weight: bold;
      letter-spacing: 1.1px;
      text-transform: uppercase;
      width: 160px;
      border: 1px solid var(--gray-light);
      border-radius: 2px;
      background-color: var(--white);
      padding: 11px;
      text-align: center;
      display: block;
    }
  }

  &__numbers {
    display: flex;
  }

  &__number-field {
    flex-grow: 1;
  }

  &__tooltip-icon {
    font-size: 30px;
    margin-right: 10px;
    color: var(--blue);
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
}

@layout-body-background: #FFF;@blue-base: #2EB8E6;@error-color: #E64545;@disabled-color: #7A8399;@btn-default-color: #000000;@btn-disable-bg: #FFF;@btn-disable-border: #CFD8E6;@input-placeholder-color: #8FA4BF;@input-bg: #F0F5FA;@input-color: #000000;@input-border-color: #F0F5FA;@input-disabled-bg: #F5F9FC;@radio-dot-color: #2FBF00;@radio-button-checked-bg: #2FBF00;@radio-button-hover-color: #39B312;@radio-disabled-button-checked-bg: #B2E6A1;@radio-button-focus-shadow: none;@radio-button-active-color: #FFFFFF;@radio-disabled-button-checked-color: #FFFFFF;@switch-color: #2FBF00;@menu-icon-size-lg: 24px;