@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?fmmopz");
  src: url("../fonts/icomoon.eot?fmmopz#iefix") format("embedded-opentype"),
    url("../fonts/icomoon.ttf?fmmopz") format("truetype"), url("../fonts/icomoon.woff?fmmopz") format("woff"),
    url("../fonts/icomoon.svg?fmmopz#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Logo-Big:before {
  content: "\e900";
  color: #002856;
}
.icon-Logo-Small-White:before {
  content: "\e901";
  color: var(--white);
}
.icon-Logo-Small:before {
  content: "\e902";
  color: #002856;
}
.icon-Close-1:before {
  content: "\e923";
  color: #8fa4bf;
}
.icon-Add:before {
  content: "\e903";
  color: #8fa4bf;
}
.icon-Copy:before {
  content: "\e904";
  color: #8fa4bf;
}
.icon-Dashboard:before {
  content: "\e905";
  color: #8fa4bf;
}
.icon-Datepicker:before {
  content: "\e906";
  color: #8fa4bf;
}
.icon-Email:before {
  content: "\e907";
  color: #8fa4bf;
}
.icon-Error:before {
  content: "\e908";
  color: #8fa4bf;
}
.icon-Export:before {
  content: "\e909";
  color: #8fa4bf;
}
.icon-Filter:before {
  content: "\e90a";
  color: #8fa4bf;
}
.icon-Import:before {
  content: "\e90b";
  color: #8fa4bf;
}
.icon-Location:before {
  content: "\e90c";
  color: #8fa4bf;
}
.icon-Notification:before {
  content: "\e90d";
  color: #8fa4bf;
}
.icon-Partners:before {
  content: "\e90e";
  color: #8fa4bf;
}
.icon-Phone:before {
  content: "\e90f";
  color: #8fa4bf;
}
.icon-Remove:before {
  content: "\e910";
  color: #8fa4bf;
}
.icon-Search:before {
  content: "\e911";
  color: #8fa4bf;
}
.icon-User:before {
  content: "\e912";
  color: #8fa4bf;
}
.icon-Users:before {
  content: "\e913";
  color: #8fa4bf;
}
.icon-Wrench:before {
  content: "\e914";
  color: #8fa4bf;
}
.icon-Warning:before {
  content: "\e924";
  color: #8fa4bf;
}
.icon-Add1:before {
  content: "\e915";
  color: #8fa4bf;
}
.icon-Asterisk:before {
  content: "\e916";
  color: #8fa4bf;
}
.icon-Back:before {
  content: "\e917";
  color: #8fa4bf;
}
.icon-Check:before {
  content: "\e918";
  color: #8fa4bf;
}
.icon-Collapse:before {
  content: "\e919";
  color: #8fa4bf;
}
.icon-Cross:before {
  content: "\e91a";
  color: #8fa4bf;
}
.icon-Down:before {
  content: "\e91b";
  color: #8fa4bf;
}
.icon-Drag:before {
  content: "\e91c";
  color: #8fa4bf;
}
.icon-Expand:before {
  content: "\e91d";
  color: #8fa4bf;
}
.icon-Next:before {
  content: "\e91e";
  color: #8fa4bf;
}
.icon-Remove1:before {
  content: "\e91f";
  color: #8fa4bf;
}
.icon-Sort:before {
  content: "\e920";
  color: #8fa4bf;
}
.icon-Spinner:before {
  content: "\e921";
  color: var(--white);
}
.icon-Up:before {
  content: "\e922";
  color: #8fa4bf;
}

@layout-body-background: #FFF;@blue-base: #2EB8E6;@error-color: #E64545;@disabled-color: #7A8399;@btn-default-color: #000000;@btn-disable-bg: #FFF;@btn-disable-border: #CFD8E6;@input-placeholder-color: #8FA4BF;@input-bg: #F0F5FA;@input-color: #000000;@input-border-color: #F0F5FA;@input-disabled-bg: #F5F9FC;@radio-dot-color: #2FBF00;@radio-button-checked-bg: #2FBF00;@radio-button-hover-color: #39B312;@radio-disabled-button-checked-bg: #B2E6A1;@radio-button-focus-shadow: none;@radio-button-active-color: #FFFFFF;@radio-disabled-button-checked-color: #FFFFFF;@switch-color: #2FBF00;@menu-icon-size-lg: 24px;