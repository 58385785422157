.filter-heading {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 25px;
  color: var(--black);
  font-variant-numeric: lining-nums;
  margin-bottom: 24px;

  display: flex;
  align-items: center;
  gap: 12px;

  &__icon {
    color: #35c0e9;
    font-size: 30px;
  }
}

.filter-row {
  .ant-col-15 {
    width: 100% !important;
    max-width: 100% !important;
  }

  .ant-col-offset-1 {
    margin: 0;
  }
}
