.recipient-list-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 8px;

  border-width: 0 2px;
  border-style: solid;
  border-color: var(--gray-lightest);

  transition: border-color 0.3s;

  &__number {
    font-size: 11px;
    font-weight: bold;
    text-align: center;
    color: rgba(143, 164, 191, 0.5);
    min-width: 16px;
    transition: color 0.3s;
  }

  &__fields {
    flex-grow: 1;
  }

  &:has(> &__remove:hover) {
    border-color: var(--red-light);
  }

  &:has(> &__remove:hover) > &__number {
    color: var(--red-light);
  }
}

@layout-body-background: #FFF;@blue-base: #2EB8E6;@error-color: #E64545;@disabled-color: #7A8399;@btn-default-color: #000000;@btn-disable-bg: #FFF;@btn-disable-border: #CFD8E6;@input-placeholder-color: #8FA4BF;@input-bg: #F0F5FA;@input-color: #000000;@input-border-color: #F0F5FA;@input-disabled-bg: #F5F9FC;@radio-dot-color: #2FBF00;@radio-button-checked-bg: #2FBF00;@radio-button-hover-color: #39B312;@radio-disabled-button-checked-bg: #B2E6A1;@radio-button-focus-shadow: none;@radio-button-active-color: #FFFFFF;@radio-disabled-button-checked-color: #FFFFFF;@switch-color: #2FBF00;@menu-icon-size-lg: 24px;