.dynamic-path-field {
  position: relative;

  &__input,
  &__highlight {
    box-sizing: border-box;
    font-family: "Fira Code", monospace !important;
    font-feature-settings: "lnum";
    font-variant-numeric: lining-nums;
    padding: 10px 15px;
    overflow: hidden;
    line-height: 1.2;
    width: 100%;
  }

  &__input {
    position: relative;
    z-index: 1;
    color: transparent !important;
    background: transparent !important;
    caret-color: black;
    outline: 0;
    resize: none;
    overflow: hidden;
    min-height: 40px;
    transition: border-color 0.3s;

    &--error {
      border-color: var(--red) !important;
    }
  }

  &__highlight {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0;
    z-index: 0;

    border: 1px solid #f0f5fa;
    border-radius: 2px;
    background-color: #f0f5fa;

    white-space: pre-wrap;
    word-wrap: break-word;

    code {
      font-family: inherit;
    }
  }

  &__var {
    font-weight: bold;
    color: #8b70f6;

    &--focused {
      background-color: rgba(139, 112, 246, 0.15);
    }

    &--invalid {
      border-bottom: 2px dashed rgba(225, 75, 75, 0.5);
    }
  }
}

@layout-body-background: #FFF;@blue-base: #2EB8E6;@error-color: #E64545;@disabled-color: #7A8399;@btn-default-color: #000000;@btn-disable-bg: #FFF;@btn-disable-border: #CFD8E6;@input-placeholder-color: #8FA4BF;@input-bg: #F0F5FA;@input-color: #000000;@input-border-color: #F0F5FA;@input-disabled-bg: #F5F9FC;@radio-dot-color: #2FBF00;@radio-button-checked-bg: #2FBF00;@radio-button-hover-color: #39B312;@radio-disabled-button-checked-bg: #B2E6A1;@radio-button-focus-shadow: none;@radio-button-active-color: #FFFFFF;@radio-disabled-button-checked-color: #FFFFFF;@switch-color: #2FBF00;@menu-icon-size-lg: 24px;