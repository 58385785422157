.ant-notification {
  .ant-notification-notice {
    background-color: var(--white);
    box-shadow: 2px 2px 25px 0 rgba(143, 164, 191, 0.25);
    max-width: 320px;
  }

  .ant-notification-notice-message {
    color: var(--black);
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
  }

  .ant-notification-notice-description {
    color: #000000;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 20px;
    font-variant-numeric: lining-nums !important;
  }

  .ant-notification-close-x {
    position: relative;
    svg {
      display: none;
    }

    &:after {
      position: absolute;
      content: "\e923";
      font-family: "icomoon";
      top: -6px;
      right: 0;
      font-size: 10px;
      font-weight: 900;
    }
  }
}
