@import "../Variable";

.suggestion-list {
  background: #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1);
  color: var(--black);
  overflow: hidden;
  position: relative;
  max-height: 400px;
  overflow-y: auto;
}

.suggestion-list-item {
  background: transparent;
  border: 1px solid transparent;
  text-align: left;
  width: 100%;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  line-height: 1.1;
  padding: 2px 4px;

  &__label {
    mark {
      font-weight: bold;
      padding: 0;
      background: none;
      color: inherit;
    }
  }

  &__type {
    .variable-type-indicator();
  }

  &--selected,
  &:hover {
    color: var(--pink);
    background: var(--pink-transparent);
  }
}

@layout-body-background: #FFF;@blue-base: #2EB8E6;@error-color: #E64545;@disabled-color: #7A8399;@btn-default-color: #000000;@btn-disable-bg: #FFF;@btn-disable-border: #CFD8E6;@input-placeholder-color: #8FA4BF;@input-bg: #F0F5FA;@input-color: #000000;@input-border-color: #F0F5FA;@input-disabled-bg: #F5F9FC;@radio-dot-color: #2FBF00;@radio-button-checked-bg: #2FBF00;@radio-button-hover-color: #39B312;@radio-disabled-button-checked-bg: #B2E6A1;@radio-button-focus-shadow: none;@radio-button-active-color: #FFFFFF;@radio-disabled-button-checked-color: #FFFFFF;@switch-color: #2FBF00;@menu-icon-size-lg: 24px;