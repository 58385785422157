.box {
  padding: 16px;
  border-radius: 2px;
  background: var(--white);
  transition: opacity 0.3s;
  position: relative;

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    margin-top: -8px;

    &:empty {
      display: none;
    }
  }

  &__title {
    text-transform: uppercase;
    color: var(--gray);
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.1em;
    margin: 0;
    font-variant: lining-nums;
  }

  &__actions {
    margin-inline: auto -8px;
  }

  &--read-only {
    opacity: 0.5;
    pointer-events: none;
  }

  &__body {
    transition: opacity 0.3s;
    &--read-only {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .ant-form-item {
    margin-bottom: 0 !important;
  }
}

@layout-body-background: #FFF;@blue-base: #2EB8E6;@error-color: #E64545;@disabled-color: #7A8399;@btn-default-color: #000000;@btn-disable-bg: #FFF;@btn-disable-border: #CFD8E6;@input-placeholder-color: #8FA4BF;@input-bg: #F0F5FA;@input-color: #000000;@input-border-color: #F0F5FA;@input-disabled-bg: #F5F9FC;@radio-dot-color: #2FBF00;@radio-button-checked-bg: #2FBF00;@radio-button-hover-color: #39B312;@radio-disabled-button-checked-bg: #B2E6A1;@radio-button-focus-shadow: none;@radio-button-active-color: #FFFFFF;@radio-disabled-button-checked-color: #FFFFFF;@switch-color: #2FBF00;@menu-icon-size-lg: 24px;