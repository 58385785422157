.full-width-button {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: #c8d6e9;
  font-weight: 700;
  border: 2px dashed #c8d6e9;
  min-height: 70px;
  cursor: pointer;
  font-size: 16px;
  transition: color 0.2s, border-color 0.2s;

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &--small {
    min-height: 40px;
  }

  &:hover {
    color: var(--blue);
    border-color: var(--blue);
  }
}

@layout-body-background: #FFF;@blue-base: #2EB8E6;@error-color: #E64545;@disabled-color: #7A8399;@btn-default-color: #000000;@btn-disable-bg: #FFF;@btn-disable-border: #CFD8E6;@input-placeholder-color: #8FA4BF;@input-bg: #F0F5FA;@input-color: #000000;@input-border-color: #F0F5FA;@input-disabled-bg: #F5F9FC;@radio-dot-color: #2FBF00;@radio-button-checked-bg: #2FBF00;@radio-button-hover-color: #39B312;@radio-disabled-button-checked-bg: #B2E6A1;@radio-button-focus-shadow: none;@radio-button-active-color: #FFFFFF;@radio-disabled-button-checked-color: #FFFFFF;@switch-color: #2FBF00;@menu-icon-size-lg: 24px;