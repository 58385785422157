.sortable-list-item {
  padding: 4px;
  background: var(--white);
  border-radius: 2px;
  display: flex;
  gap: 8px;
  align-items: center;
  border-width: 0 2px;
  border-style: solid;
  border-color: var(--gray-lightest);

  transition: border-color 0.3s;

  .ant-form-item {
    margin: 0 !important;
  }

  &--ghost {
    z-index: 9999;
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
    .ant-form-item {
      margin: 0 !important;
    }
  }

  &__handle {
    font-size: 24px;
    color: var(--gray);
    height: 40px;
    cursor: grab;
    pointer-events: initial;
    display: flex;
    place-items: center;
  }

  &--ghost &__handle {
    cursor: grabbing;
  }

  &__remove {
    padding: 8px;
    &:hover {
      color: var(--red);
    }
  }

  &:has(&__remove:hover) {
    border-color: var(--red-light);
  }

  &__content {
    flex-grow: 1;
  }
}

@layout-body-background: #FFF;@blue-base: #2EB8E6;@error-color: #E64545;@disabled-color: #7A8399;@btn-default-color: #000000;@btn-disable-bg: #FFF;@btn-disable-border: #CFD8E6;@input-placeholder-color: #8FA4BF;@input-bg: #F0F5FA;@input-color: #000000;@input-border-color: #F0F5FA;@input-disabled-bg: #F5F9FC;@radio-dot-color: #2FBF00;@radio-button-checked-bg: #2FBF00;@radio-button-hover-color: #39B312;@radio-disabled-button-checked-bg: #B2E6A1;@radio-button-focus-shadow: none;@radio-button-active-color: #FFFFFF;@radio-disabled-button-checked-color: #FFFFFF;@switch-color: #2FBF00;@menu-icon-size-lg: 24px;