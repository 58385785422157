@import "Variables/Variable";

.rich-text-editor {
  transition: border-color 0.3s;
  background: var(--form-field-bg);
  border: 1px solid var(--form-field-bg);

  &:not(&--read-only) {
    min-height: 88px;
  }

  &:focus-within:not(&--error):not(&--read-only) {
    outline: none;
    border-color: var(--blue);
  }

  &--error {
    border-color: var(--red);
  }

  .tiptap {
    &:focus {
      outline: none;
    }

    padding: 8px;

    > :first-child {
      margin-top: 0;
    }

    > :last-child {
      margin-bottom: 0;
    }

    h1,
    h2,
    h3,
    ol,
    ul {
      margin-block: 8px;
    }

    div {
      margin: 0;
    }

    .variable();
  }
}

@layout-body-background: #FFF;@blue-base: #2EB8E6;@error-color: #E64545;@disabled-color: #7A8399;@btn-default-color: #000000;@btn-disable-bg: #FFF;@btn-disable-border: #CFD8E6;@input-placeholder-color: #8FA4BF;@input-bg: #F0F5FA;@input-color: #000000;@input-border-color: #F0F5FA;@input-disabled-bg: #F5F9FC;@radio-dot-color: #2FBF00;@radio-button-checked-bg: #2FBF00;@radio-button-hover-color: #39B312;@radio-disabled-button-checked-bg: #B2E6A1;@radio-button-focus-shadow: none;@radio-button-active-color: #FFFFFF;@radio-disabled-button-checked-color: #FFFFFF;@switch-color: #2FBF00;@menu-icon-size-lg: 24px;