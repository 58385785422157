.login-screen {
  display: flex;
  justify-content: center;
  align-items: center;

  height: calc(100vh - var(--main-content-padding-bottom) - var(--main-content-padding-top));

  .reset-password-form {
    width: 280px;

    .login-description {
      margin-top: 36px;
      color: var(--black);
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0;
      margin-bottom: 14px;
    }
    .ant-row.ant-form-item {
      margin-bottom: 30px;
    }
    .reset-password-form-button {
      width: 100%;
      text-transform: uppercase;
    }
    .link-wrapper {
      margin-top: 16px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 20px;
      font-family: inherit;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 20px;
      color: var(--black);
    }
    .login {
      color: var(--blue);
      font-weight: 600;
      margin-left: 5px;
    }
  }
}

@layout-body-background: #FFF;@blue-base: #2EB8E6;@error-color: #E64545;@disabled-color: #7A8399;@btn-default-color: #000000;@btn-disable-bg: #FFF;@btn-disable-border: #CFD8E6;@input-placeholder-color: #8FA4BF;@input-bg: #F0F5FA;@input-color: #000000;@input-border-color: #F0F5FA;@input-disabled-bg: #F5F9FC;@radio-dot-color: #2FBF00;@radio-button-checked-bg: #2FBF00;@radio-button-hover-color: #39B312;@radio-disabled-button-checked-bg: #B2E6A1;@radio-button-focus-shadow: none;@radio-button-active-color: #FFFFFF;@radio-disabled-button-checked-color: #FFFFFF;@switch-color: #2FBF00;@menu-icon-size-lg: 24px;