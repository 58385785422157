.openplay-field-tree {
  background: transparent;

  &.ant-tree-show-line .ant-tree-switcher {
    background: transparent;
  }
}

.openplay-field {
  display: inline-flex;
  gap: 4px;
  align-items: center;

  &__title {
    border: 1px solid var(--gray-light);
    line-height: 1;
    padding: 4px;
    border-radius: 2px;
    display: inline-flex;
    gap: 4px;
    align-items: center;

    &:hover {
      border-color: var(--blue);
    }
  }

  &--hidden &__title {
    opacity: 0.25;
  }

  &__name {
    font-family: var(--monospace-font);
  }

  &__label {
    color: var(--gray);
    &:before {
      content: "(";
    }
    &:after {
      content: ")";
    }
  }

  &__type {
    background: var(--pink);
    font-family: var(--monospace-font);
    color: var(--white);
    display: inline-block;
    padding: 2px 4px;
    border-radius: 2px;

    &--unknown {
      background: var(--gray-light);
    }
  }

  &__actions {
    display: inline-flex;
    visibility: hidden;
    align-items: center;

    button {
      font-size: 16px;
      padding: 0;
    }
  }

  &:hover &__actions {
    visibility: visible;
  }

  code {
    color: #427ac5;
  }
}

@layout-body-background: #FFF;@blue-base: #2EB8E6;@error-color: #E64545;@disabled-color: #7A8399;@btn-default-color: #000000;@btn-disable-bg: #FFF;@btn-disable-border: #CFD8E6;@input-placeholder-color: #8FA4BF;@input-bg: #F0F5FA;@input-color: #000000;@input-border-color: #F0F5FA;@input-disabled-bg: #F5F9FC;@radio-dot-color: #2FBF00;@radio-button-checked-bg: #2FBF00;@radio-button-hover-color: #39B312;@radio-disabled-button-checked-bg: #B2E6A1;@radio-button-focus-shadow: none;@radio-button-active-color: #FFFFFF;@radio-disabled-button-checked-color: #FFFFFF;@switch-color: #2FBF00;@menu-icon-size-lg: 24px;