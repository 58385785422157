.summary {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;

  &__item {
    margin-right: 30px;

    &:last-child {
      margin: 0;
    }
  }

  &__label,
  &__value {
    display: inline-block;
    vertical-align: middle;
  }

  &__label {
    color: var(--gray);
    margin: 2px 8px 0 0;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 1.1px;
    line-height: 15px;
    text-transform: uppercase;
  }

  &__value {
    color: var(--black);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 15px;
  }
}

@layout-body-background: #FFF;@blue-base: #2EB8E6;@error-color: #E64545;@disabled-color: #7A8399;@btn-default-color: #000000;@btn-disable-bg: #FFF;@btn-disable-border: #CFD8E6;@input-placeholder-color: #8FA4BF;@input-bg: #F0F5FA;@input-color: #000000;@input-border-color: #F0F5FA;@input-disabled-bg: #F5F9FC;@radio-dot-color: #2FBF00;@radio-button-checked-bg: #2FBF00;@radio-button-hover-color: #39B312;@radio-disabled-button-checked-bg: #B2E6A1;@radio-button-focus-shadow: none;@radio-button-active-color: #FFFFFF;@radio-disabled-button-checked-color: #FFFFFF;@switch-color: #2FBF00;@menu-icon-size-lg: 24px;