.filterable-layout {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  .ant-page-header-content {
    margin: 0 0 20px;

    .buttons-wrapper {
      display: flex;
      justify-content: space-between;
    }
  }

  .filterable-sidebar {
    background-color: transparent;
    width: calc(240px - 20px) !important;
    margin: 0 20px 0 0;
    flex: unset !important;
    min-width: auto !important;

    &__actions {
      display: flex;
      gap: 8px;
      margin-top: 16px;

      button {
        flex-grow: 1;
      }
    }
  }

  .ant-form-item {
    margin: 0 0 20px;
  }
}

.content--secondary .filterable-layout {
  &,
  .ant-layout {
    background-color: transparent;
  }

  .ant-page-header-heading {
    justify-content: start;
    align-items: baseline;
    flex-wrap: wrap;
  }

  .ant-page-header-heading-left {
    width: calc(240px - 20px);
    margin-right: 20px;
  }

  .filterable-sidebar {
    background-color: var(--white);
    padding: 12px 10px;

    &.ant-layout-sider-collapsed {
      padding: 0;
    }
  }
}

.advanced-filters {
  display: flex;
  align-items: center;
  margin: 0 0 31px !important;
  cursor: pointer;

  &__icon {
    font-size: 28px;
    margin-right: 8px;
    color: var(--gray);
  }

  &__heading {
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 1.1px;
    text-transform: uppercase;
    color: var(--gray);
  }
}

.btn-filters .anticon svg {
  width: 12px;
  height: 12px;
}

.sidebar-collapsed {
  .btn-filters {
    background-color: var(--green);
    border-color: transparent;
    color: var(--white);

    &:hover {
      background-color: var(--green-darker);
    }

    .anticon {
      color: var(--white);
      transform: rotate(-180deg);
    }
  }
}

.filters-list {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .ant-form-item {
    margin: 0 !important;
  }
}

@layout-body-background: #FFF;@blue-base: #2EB8E6;@error-color: #E64545;@disabled-color: #7A8399;@btn-default-color: #000000;@btn-disable-bg: #FFF;@btn-disable-border: #CFD8E6;@input-placeholder-color: #8FA4BF;@input-bg: #F0F5FA;@input-color: #000000;@input-border-color: #F0F5FA;@input-disabled-bg: #F5F9FC;@radio-dot-color: #2FBF00;@radio-button-checked-bg: #2FBF00;@radio-button-hover-color: #39B312;@radio-disabled-button-checked-bg: #B2E6A1;@radio-button-focus-shadow: none;@radio-button-active-color: #FFFFFF;@radio-disabled-button-checked-color: #FFFFFF;@switch-color: #2FBF00;@menu-icon-size-lg: 24px;