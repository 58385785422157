.ant-breadcrumb {
  .ant-breadcrumb-link {
    a {
      font-size: 12px;
      color: var(--blue);
      font-weight: 600;
    }
  }

  .anticon {
    font-size: 10px;
    color: var(--gray);
  }

  span {
    &:last-child {
      .ant-breadcrumb-link {
        a {
          color: var(--black);
          font-weight: 400;
        }
      }
    }
  }
}
