.sql-editor {
  position: relative;

  &__prettify {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 10;
    border-radius: 2px;
    color: rgba(255, 255, 255, 0.8);
    background: rgba(255, 255, 255, 0.1);
    cursor: pointer;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 1.1px;
    font-weight: bold;
    line-height: 1;
    padding: 2px 4px;
    transition: 0.3s background-color;

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }

  .cm-editor {
    outline-color: transparent;
    transition: outline-color 0.3s;
    border-radius: 2px;
  }

  .cm-gutters {
    border-radius: 2px 0 0 2px;
  }

  .cm-placeholder {
    //color: var(--gray);
  }

  .cm-focused {
    outline: 2px solid var(--blue);
  }

  .cm-content {
    padding: 10px 8px;
  }

  .cm-foldPlaceholder {
    background: rgba(255, 255, 255, 0.1);
    border: 0;
    padding: 0 8px;
    margin: 0 8px;
  }

  .cm-activeLine {
    background: rgba(255, 255, 255, 0.05);
  }

  .cm-completionIcon {
    width: 16px;
    height: 16px;
    padding: 0;
    margin-right: 4px;
    vertical-align: middle;

    &-schema {
      background: url("./icons/schema.svg") center center no-repeat;
      background-size: contain;
    }
    &-table {
      background: url("./icons/table.svg") center center no-repeat;
      background-size: contain;
    }
    &-column {
      background: url("./icons/column.svg") center center no-repeat;
      background-size: contain;
    }
  }

  &--error {
    .cm-editor {
      outline: 2px solid var(--red);

      &.cm-focused {
        outline: 2px solid var(--red);
      }
    }
  }
}

@layout-body-background: #FFF;@blue-base: #2EB8E6;@error-color: #E64545;@disabled-color: #7A8399;@btn-default-color: #000000;@btn-disable-bg: #FFF;@btn-disable-border: #CFD8E6;@input-placeholder-color: #8FA4BF;@input-bg: #F0F5FA;@input-color: #000000;@input-border-color: #F0F5FA;@input-disabled-bg: #F5F9FC;@radio-dot-color: #2FBF00;@radio-button-checked-bg: #2FBF00;@radio-button-hover-color: #39B312;@radio-disabled-button-checked-bg: #B2E6A1;@radio-button-focus-shadow: none;@radio-button-active-color: #FFFFFF;@radio-disabled-button-checked-color: #FFFFFF;@switch-color: #2FBF00;@menu-icon-size-lg: 24px;