@import "_typograpfy.less";
@import "_forms.less";
@import "_tables.less";
@import "_pagination.less";
@import "_tabs.less";
@import "_breadcrumb.less";
@import "_filters.less";
@import "_popup.less";
@import "_notification.less";

:root {
  --black: #0e101a;
  --white: #ffffff;

  --green: #2fbf00;
  --green-darker: #39b312;

  --red: #e14b4b;
  --red-light: #ed9393;

  --yellow: #faad14;

  --gray: #8fa4bf;
  --gray-light: #cfd8e6;
  --gray-lightest: #f0f5fa;

  --blue: #2eb8e6;
  --blue-darker: #21abd9;
  --blue-lighter: #91daf2;
  --blue-transparent-20: rgba(46, 184, 230, 0.2);
  --blue-transparent-10: rgba(46, 184, 230, 0.1);

  --pink: #8b70f6;
  --pink-lighter: #c5b8fb;
  --pink-transparent: rgba(139, 112, 246, 0.1);

  --form-field-bg: var(--gray-lightest);
  --form-field-bg-darker: rgb(230, 237, 248);

  --header-height: 52px;
  --sidebar-width: 220px;

  --main-content-padding-top: 40px;
  --main-content-padding-bottom: 20px;

  --base-font: "Raleway", sans-serif;
  --monospace-font: "Fira Code", monospace;
}

.flex-col {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.flex-row {
  display: flex;
  gap: 8px;
  align-items: center;
}

.gap-1 {
  gap: 4px;
}

.flex-grow {
  flex-grow: 1;
}

#webpack-dev-server-client-overlay {
  display: none !important;
}

.field-list {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__add {
    align-self: flex-start;
  }
}

.list-item {
  padding: 8px;
  background: var(--white);
  border-inline: 2px solid var(--gray-lightest);
  transition: border-color 0.3s;

  &__remove:hover {
    color: var(--red);
  }

  &:has(&__remove:hover) {
    border-color: var(--red-light);
  }

  display: flex;
  align-items: center;

  &__body {
    flex-grow: 1;

    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
