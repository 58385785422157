.sidebar {
  width: var(--sidebar-width);
  background-color: var(--black) !important;
  overflow: auto;

  .ant-menu {
    .anticon svg {
      display: block;
    }

    &.ant-menu-dark {
      background-color: var(--black) !important;
    }

    &.ant-menu-inline,
    &.ant-menu-vertical {
      .ant-menu-item {
        margin: 0;
      }
      .ant-menu-submenu-title {
        margin: 0;
      }
    }
  }

  .logo {
    pointer-events: none;
    height: var(--header-height);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  .menu-item {
    display: flex;
    align-items: center;

    &__icon {
      font-size: 24px;
    }
  }
}

@layout-body-background: #FFF;@blue-base: #2EB8E6;@error-color: #E64545;@disabled-color: #7A8399;@btn-default-color: #000000;@btn-disable-bg: #FFF;@btn-disable-border: #CFD8E6;@input-placeholder-color: #8FA4BF;@input-bg: #F0F5FA;@input-color: #000000;@input-border-color: #F0F5FA;@input-disabled-bg: #F5F9FC;@radio-dot-color: #2FBF00;@radio-button-checked-bg: #2FBF00;@radio-button-hover-color: #39B312;@radio-disabled-button-checked-bg: #B2E6A1;@radio-button-focus-shadow: none;@radio-button-active-color: #FFFFFF;@radio-disabled-button-checked-color: #FFFFFF;@switch-color: #2FBF00;@menu-icon-size-lg: 24px;