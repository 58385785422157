.ant-modal-content {
  border-radius: 2px;
  background-color: var(--white);
  box-shadow: 2px 2px 25px 0 rgba(143, 164, 191, 0.25);

  .ant-modal-header {
    padding: 16px 20px;
    background-color: var(--gray-lightest);
    border: 0;

    .ant-modal-title {
      font-size: 11px;
      font-weight: bold;
      letter-spacing: 1.1px;
      text-transform: uppercase;
      line-height: 12px;
    }
  }

  .ant-modal-close:hover {
    color: var(--blue);
  }

  .ant-modal-close-x {
    width: 44px;
    height: 44px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 24px;
      height: 24px;
      fill: var(--gray);
    }
  }

  .ant-modal-body {
    padding: 16px;
  }

  .ant-modal-footer {
    padding: 0 16px 16px;
    border: 0;
    display: flex;
    justify-content: flex-end;

    &:empty {
      display: none;
    }
  }
}
