// Typography
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.h {
  font-family: var(--base-font);
  font-weight: 700;
  margin: 0 0 22px;
  color: var(--black);
}

h1,
.h1 {
  font-size: 24px;
}

h2,
.h2 {
  font-size: 18px;
}

h3,
.h3 {
  font-size: 14px;
}

h4,
.h4 {
  font-size: 12px;
}

h5,
.h5 {
  font-size: 10px;
}

h6,
.h6 {
  font-size: 8px;
}

.ant-typography h1 {
  font-size: 20px;
}
.ant-typography h2 {
  font-size: 18px;
}

p {
  margin: 0 0 8px;
}

p:last-child {
  margin-bottom: 0;
}

a {
  text-decoration: none;

  color: var(--blue);
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

body {
  color: var(--black);
  background: var(--white);
  min-width: 320px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--base-font);
  font-variant-numeric: lining-nums !important;
}

// adaptive images
img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

// google map fix
.gm-style img {
  max-width: none;
}

button {
  display: block;
  border: none;
  outline: none;
  text-align: center;
}

.ant-page-header-heading {
  align-items: center;
}

.ant-page-header {
  padding: 0;
  font-variant-numeric: lining-nums;
}

.ant-page-header-content {
  padding: 0;
}

.ant-page-header-heading-extra {
  margin: 0;
}

// Button
.ant-btn {
  height: 40px;
  box-shadow: none;
  font-weight: 700;
  color: var(--black);
  padding: 0 20px;
  font-size: 11px;
  line-height: 15px;
  border-color: var(--gray-light);
  outline: none;
  text-transform: uppercase;
  border-radius: 2px;
  letter-spacing: 1.1px;
  background-color: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  -webkit-font-feature-settings: "lnum";
  font-feature-settings: "lnum";

  &:hover {
    border-color: var(--blue);
    color: var(--black);
  }

  &:disabled {
    border-color: var(--gray-light);
    color: var(--black);
    background: inherit;
    opacity: 0.5;
  }

  &:disabled:hover {
    background: inherit;
  }

  &:focus {
    color: var(--black);
  }

  &.ant-btn-dangerous {
    color: var(--red);
    border-color: var(--red);

    &:hover,
    &:focus {
      color: var(--red);
      border-color: var(--red);
    }
  }

  &:not(.ant-btn-icon-only) .anticon {
    font-size: 16px;
    display: block;

    svg {
      display: block;
    }
  }

  &.ant-btn-primary {
    color: var(--white);
    background: var(--blue);
    border-color: var(--blue);

    &:hover {
      background-color: var(--blue-darker);
      color: var(--white);
    }

    &:disabled {
      background-color: var(--blue-lighter);
      color: var(--white);

      &:hover {
        background-color: var(--blue-lighter);
        color: var(--white);
      }
    }

    &:focus {
      color: var(--white);
    }
  }

  &.ant-btn-text {
    border: 0 !important;
    padding: 8px;
    display: flex;
    height: auto;
    color: var(--gray);
    line-height: 1;

    &:disabled {
      color: var(--gray);
      opacity: 0.5;
    }

    &:focus {
      background: transparent;
    }

    &:hover {
      background: transparent;
      color: var(--blue);
    }
  }

  &.ant-btn-sm {
    padding: 0 16px;
    height: 32px;
  }
}

.ant-page-header-heading-left {
  margin: 0;
}

//ant-tooltip

.ant-tooltip {
  .ant-tooltip-inner {
    font-size: 12px;
    background-color: var(--black) !important;
  }
}

.ant-divider-horizontal.ant-divider-with-text {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.1em;
}

.ant-divider-inner-text {
  color: var(--gray-light);
}

pre:not(:has(> .hljs)):not(.dynamic-path-field__highlight) {
  font-size: 85%;
  background: rgba(150, 150, 150, 0.1);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-radius: 4px;
  margin: 0;
  padding: 8px;
  white-space: pre-wrap;
}
